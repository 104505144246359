import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../../services/axios';
import { ContextAplication } from '../../../context';

import { BackButton } from '../../../components/atoms/BackButton';
import { RoutineBasicInformationForm } from '../../../components/molecules/RoutineBasicInformationForm';
import { RoutineSpecialtiesForm } from '../../../components/molecules/RoutineSpecialtiesForm';
import { RoutineTabs } from '../../../components/organisms/RoutineTabs';
import { RoutineTabCards } from '../../../components/organisms/RoutineTabCards';
import { RoutineLinks } from '../../../components/molecules/RoutineLinks';
import { RoutinesUpdateContainer } from './style';
import { Keywords } from '../../../components/organisms/Keywords';
import { SwitchButton } from '../../../components/atoms/SwitchButton';

export function RoutinesUpdate() {
  const history = useHistory();
  const { id } = useParams();

  const { handleGetCurrentContent } = useContext(ContextAplication);

  const [routine, setRoutine] = useState(null);
  const [routineTitle, setRoutineTitle] = useState(null);
  const [reviewHistory, setReviewHistory] = useState(null);

  const [tabs, setTabs] = useState(null);

  async function getResumeTabCards() {
    try {
      const resume_tab_id = tabs?.find(tab => tab.tab_id._id === '5fa0762c17835259eef7e238') 
      const response = await api.get(`routine-tabs/${resume_tab_id._id}`);

      const cards_ids = response.data.routine_tabs_cards_id?.map(tab_card => tab_card.card_id._id)

      return {routineTitle: routineTitle.title, cards_ids};
    } catch (error) {
      console.log(error);
    }
  }
  

  async function handleGetRoutine() {
    const response = await api.get(`routine-populate/${id}`);
    handleGetReviewHistory();
    setRoutine(response.data);
    setRoutineTitle({
      title: response.data.title,
      legend: response.data.legend,
    });
  }

  async function handleGetReviewHistory() {
    const response = await api.get(`/routine/review/history/${id}`);

    setReviewHistory(response.data[0]);
  }

  function handleAccessContentPage() {
    handleGetCurrentContent(null);
    history.push(`/routines/updateContent/${id}`);
  }

  useEffect(() => {
    handleGetRoutine();
  }, []);

  const [isReviewLoading, setReviewLoading] = useState(false);

  async function handleReviewRoutine(e) {
    setReviewLoading(true);
    console.log(e.target.checked);
    await api.put(`/routine/review/${id}`, { reviewValue: e.target.checked });

    await handleGetRoutine();
    setReviewLoading(false);
  }

  return (
    <RoutinesUpdateContainer>
      <BackButton route="routines" />
      {routine && (
        <>
          <div className="routine__header">
            <h2 className="title">
              Rotina: {routineTitle?.title} <br /> {routineTitle?.legend}
            </h2>

            {isReviewLoading ? (
              <h1>Carregando...</h1>
            ) : (
              <div className="review__section">
                <SwitchButton
                  leftText="Não revisado"
                  rightText="Revisado"
                  checked={routine.is_reviewed}
                  onChange={handleReviewRoutine}
                />
                {reviewHistory && (
                  <>
                    <h3>Ultima alteração feita por: </h3>
                    <h3>{reviewHistory?.manager_user_id?.email}</h3>
                    <h3>
                      em{' '}
                      {new Date(reviewHistory?.changedAt).toLocaleDateString(
                        'pt-BR'
                      )}
                    </h3>
                  </>
                )}
              </div>
            )}
          </div>
          <RoutineBasicInformationForm
            routine={routine}
            setRoutineTitle={setRoutineTitle}
            getResumeTabCards={getResumeTabCards}
          />
          <RoutineSpecialtiesForm routine={routine} />

          <Keywords
            keywordArray={routine.keywords_id}
            route="routine"
            parentId={id}
            resumeTabCardsIds={getResumeTabCards}
            callbackFunction={handleGetRoutine}
          />

          <RoutineTabs tabs={tabs} setTabs={setTabs} routine={routine} />

          <RoutineTabCards tabs={tabs} routine_keywords={routine.keywords_id} 
              routine_title={routineTitle?.title} />

          <button
            id="content_navigation"
            className="content__button"
            onClick={handleAccessContentPage}
          >
            Acessar conteúdo
          </button>
          <RoutineLinks links={routine.link_manager_id} routineId={id} />
        </>
      )}
    </RoutinesUpdateContainer>
  );
}
